@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&display=swap");



* {
  font-family: "Nunito", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  transition: all 0.2s linear;
  color: var(--primary-color);
}

*::selection {
  background: var(--secondary-color);
  color: #fff;
}

html {
  /* font-size: 50%; */
  width: 100%;
  overflow-x: hidden;
  scroll-padding-top: 6.5rem;
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  /* width: 0; */
}

button {
  cursor: pointer;
}

.main-app {
  color: var(--primary-color);
  background: var(--body-background);
  padding: 1px 0 1px 0px;
  /* min-height: 100vh; */
}

.toast-container-class {
  font-size: 18px;;
  right: 0;
}

.modal-dialog {
  padding: 0;
}

.modal-dialog-centered {
  justify-content: center;
}

.modal-content {
  padding: 30px;
  border-radius: 10px;
}

body.modal-open {
  overflow: auto;
}

.green-text {
  color: var(--secondary-color);
}

.gray-text {
  color: var(--sub-text-color);
}

.react-confirm-alert-body h1 {
  font-weight: bolder;
  font-size: 2.455rem;
}

.react-confirm-alert-body {
  font-weight: bold;
  font-size: 1.755rem;
}

.react-confirm-alert-button-group button:first-child {
  background-color: var(--secondary-color);
}
.react-confirm-alert-button-group button:last-child {
  background-color: #d23b3b;
}
#home{
  width: 100%;
    max-width: 100% !important;
    padding: 0;
    margin: 0;
}
#home .swiper-slide img{
  border-radius: 0;
}
#search-box{
  border: 1px solid #eaeaea;
  padding: 14px;
  font-size: 11px;
  width: 100%;
}
.header_button{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.header_button path{
  color: #fff;
}
.header_button button{
  right: -8px;
  top: 0;
  background-color: #009245;
  border-radius: 50%;
  line-height: 45px;
  width: 45px;
  height: 45px;
  padding: 0px !important;
  border: none;
  z-index: 9;
  color: #fff;
  font-size: 23px;
  margin: 0 13px 0 0 !important;
}
.header-search {
    position: relative;
    bottom: 50%;
    transform: translateY(100%);
    width: 50%;
}
.header-search button{
  position: absolute;
  right: -8px;
  top: 0;
  background-color: #009245;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  line-height: 45px;
  width: 45px;
  height: 45px;
  padding: 0;
  border: none;
  z-index: 9;
  color: #fff;
  cursor: pointer;
}
.back-top-container {
  height: 50px;
  text-align: center;
  width: 50px;
  background-color: var(--secondary-color) !important;
  border-color: transparent !important;
  color: white;
  margin-right: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.scroll-to-top {
  position: fixed;
  bottom: 60px;
  right: 0;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
  z-index:10;
}
.scroll-to-top svg path{
  color:white;
}

/* media queries */

@media (max-width: 468px) {
  /* html {
    font-size: 40%;
  } */
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media only screen and (min-width: 1300px) {
  .container {
    max-width: 1610px !important;
  }
}
@media (max-width: 768px) {
  /* .modal-dialog {
    min-width: 90vw;
  } */

  .modal-content {
    padding: 15px;
  }
}

.see-all {
  font-size: 18px;
  padding: 10px 25px;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 600;
  letter-spacing: 0.5px;
  background-color: var(--secondary-color);
  color: #fff;
}

@media (max-width: 479px) {
  .see-all{
    font-size: 14px;
  }
}
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance:textfield; 
  margin: 0;
}

input[type=number]{
  -moz-appearance: textfield;
}

.popup.modal .modal-content {
  background:transparent !important;
  border: 0 !important;
}
.popup.modal .modal-content .modal-header {
  border: 0;
  align-self: self-end;
  cursor: pointer;
}

 .fssai-details {
  display: flex;
  justify-self: flex-start;
  align-items: center;
  gap: 10px;
  margin: 20px 0;

}
 .fssai-details .image-container img {
  height: 25px;
  object-fit: contain;
}

 .fssai-details .fssai-license-no span {
  font-size: 16px;
  font-weight: 600;
}

.no-data-img {
  width: 350px;
  aspect-ratio: 1/1;
  object-fit: contain;
}