.about-us{
    padding-bottom: 30px;
    min-height: 77vh;
}
.about-container {
    padding: 30px;
    border-radius: 8px;
    background-color: var(--container-bg);
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 2.355rem;
}

.about-container h2{
    font-size: 4rem;
    font-weight: bolder;
    text-align: center;
    background-color: var(--secondary-color);
    padding: 5px;
    border-radius: 8px;
    color: var(--primary-color);
}

.about-container h3{
    font-size: 3rem;
    font-weight: bold;
    margin-top: 30px;
}