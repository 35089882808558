    .lottie-content {
        position: absolute;
        top: 0;
    }

    .cover {
        height: 200px;
    }

    .cover .title {
        position: relative;
        bottom: 50%;
        text-align: center;
    }

    @media (max-width:479px) {
        .cover .title {
            position: relative;
            bottom: 69%;
            text-align: center;
        }
    }

    .cover .title h3 {
        font-size: 2.385rem;
        font-weight: 800;
        color: var(--text-field-color);
    }

    .cover .title span {
        font-size: 1.985rem;
        font-weight: 600;
        color: var(--text-field-color);
    }

    .cover .title span.active {
        color: var(--secondary-color);
    }


    .cover img {
        height: 100%;
        width: 100%;
        filter: brightness(50%);
        z-index: -1;
        background-size: cover;
        object-fit: cover;
        background-position: center center;
    }


    @media (max-width:479px) {

        .cover {
            height: 115px;
        }

    }

    .checkout-container {
        margin-top: 20px;
        padding-bottom: 30px;
        display: flex;
        gap: 20px;
    }

    .checkout-container .checkout-component {
        border-radius: 8px;
    }

    .checkout-container .checkout-component .heading,
    .promo-section .heading {
        background: var(--secondary-color);
        color: var(--text-field-color) !important;
        font-size: 1.755rem;
        font-weight: 600;
        padding: 8px 15px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

    }

    .promo-section .heading span {
        color: #fff;
    }

    .order-bill {
        background-color: var(--second-cards-color);
    }

    .order-bill .heading {
        width: 100%;

    }

    .order-bill .order-details .summary span {
        font-size: 14px;
    }

    .checkout-container .order-container {
        flex-grow: 1
    }

    .checkout-container .checkout-util-container,
    .checkout-container .order-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .checkout-container .checkout-util-container .billibg-address-wrapper,
    .checkout-container .checkout-util-container .delivery-day-wrapper,
    .checkout-container .checkout-util-container .delivery-time-wrapper,
    .checkout-container .order-container .payment-wrapper {
        display: inline-flex;
        flex-direction: column;
        background-color: var(--second-cards-color);
    }

    .checkout-container .order-container .payment-wrapper .form-check-label:last-child {
        border: 0 !important;
    }

    .checkout-container .checkout-util-container .time-slot-container {
        display: flex;
        flex-direction: column;
        margin: 5px 15px;
        width: 140px;
        height: fit-content;
        border-radius: 8px;
        border: 1px solid #ddd;
    }

    .checkout-container .checkout-util-container .time-slot-container div:first-child {
        padding: 4px 10px;
        background: var(--secondary-color);
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        text-align: center;
    }

    .checkout-container .checkout-util-container .time-slot-container div:last-child {
        text-align: center;
        padding: 4px 10px;
        font-size: 16px;
        font-weight: 600;
    }


    .checkout-container .order-container {
        min-width: 320px;
    }

    .checkout-container .order-container .payment-wrapper .form-check-label {
        border-bottom: 1px solid #ddd;
    }

    .checkout-container .order-container .payment-wrapper .form-check-label input[type="radio"]:checked {
        background-color: var(--secondary-color);
        padding: 5px;
        outline-offset: 3px;
        outline: 1px solid;
    }

    .checkout-container .order-container .payment-wrapper .form-check-label input[type="radio"] {
        appearance: none;
        width: 10px !important;
        align-self: center !important;
        margin: 0 !important;
        height: 10px !important;
        border-radius: 50%;
        border: 0 !important;
        outline-offset: 3px;
        outline: 1px solid;
    }

    .checkout-container .order-container .payment-wrapper div {
        padding: 8px 0;
        margin: 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 600;

    }

    .checkout-container .order-container .payment-wrapper div img {
        width: 40px;
        height: 40px;
    }

    .checkout-container .order-container .payment-wrapper div:last-child {
        border-bottom: none;
    }

    .checkout-container .order-container .order-summary-wrapper .summary {
        padding: 20px;
        font-size: 1.555rem;
        font-weight: 700;
    }

    .checkout-container .order-container .order-summary-wrapper .summary>div {
        padding: 5px;
        margin-top: 30px;
    }

    .checkout-container .order-container .order-summary-wrapper .summary .total {
        margin-top: 5px;
        border-top: 1px solid #eee;
    }

    .checkout-container .order-container .order-summary-wrapper .summary .total .total-amount span {
        color: var(--secondary-color);
    }


    .checkout-container .order-container .order-summary-wrapper .summary .button-container button {
        background: var(--secondary-color);
        border-radius: 8px;
        width: 100%;
        color: var(--text-field-color);
        padding: 8px 0;

    }





    @media screen and (max-width: 64rem) {
        .checkout-container {
            flex-direction: column;
        }
    }


    /* react calender */
    .react-calendar {
        font-family: inherit;
        font-size: 1.745rem;
        border: none;
        min-width: 100%;
    }

    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next-button {
        font-size: 5rem;
    }

    .react-calendar__navigation__label {
        font-size: 22px;
        font-weight: bold;
    }

    .react-calendar__navigation__next2-button,
    .react-calendar__navigation__prev2-button {
        display: none;
    }

    .react-calendar__month-view__weekdays__weekday abbr[title] {
        font-size: 18px;;
        color: var(--secondary-color);
        text-decoration: none;
    }

    .react-calendar__month-view__days {
        margin-top: 20px;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
        opacity: 0;
    }

    .react-calendar__tile.react-calendar__month-view__days__day--weekend {
        background-color: #ddd;
    }

    .react-calendar__tile--now:enabled {
        background: var(--secondary-color);

    }

    .react-calendar__tile--active {
        background-color: var(--secondary-color) !important;
        color: #fff !important;
    }

    .stripe-container {

        background: var(--second-cards-color)
    }

    .stripe-container .heading {
        color: var(--secondary-color);
        font-size: 2.555rem;
        font-weight: bolder;
    }

    .stripe-container button.close-stripe {
        background: none;
        font-size: 2.455rem;
    }

    .stripe-container .pay-stripe {
        background: var(--secondary-color);
        color: var(--text-field-color);
        width: 100%;
        font-size: 2.355rem;
        margin-top: 30px;
        border-radius: 8px;
        padding: 5px;

    }

    .FormRow div div input {
        border: 5px solid black;
    }

    .success_modal .success_body {
        font-size: 24px;
        border: 0;
        text-align: center;
    }

    .success_modal .success_header {
        border: 0;
    }

    .success_modal .success_footer {
        border: 0;
        justify-content: center;
    }

    .success_modal .success_footer .checkout_btn {
        width: 70%;
        font-size: 14px;
        z-index: 9;
        background-color: var(--secondary-color);
        border: 0;
        font-weight: 700;
    }

    .order-container .promo-section {
        margin-bottom: 20px;
    }

    .order-container .promo-section .promo-wrapper {
        padding: 20px;
        font-size: 1.555rem;
        background-color: var(--second-cards-color);
        font-weight: 700;

    }

    .order-container .promo-section .promo-wrapper .promo-container .promo-button {
        font-size: 16px;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
    }

    .order-container .promo-section .promo-wrapper .promo-container .promo-button {
        font-size: 16px;

    }

    .order-container .promo-section .promo-wrapper .promo-container .promo-button button {
        background-color: var(--second-cards-color);
        padding: 8px;
        border-radius: 5px;
        border: 2px solid #ddd;
        font-size: 14px;
        color: #000;
    }

    .order-container .promo-section .promo-wrapper .promo-container .promo-code {
        background-color: var(--secondary-color-light);
        border: 1px dashed var(--secondary-color);
        padding: 16px;
        font-size: 14px;
        font-weight: 600;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .order-container .promo-section .promo-wrapper .promo-container .promo-code .promo-name {
        font-weight: 500;
    }

    .order-container .promo-section .promo-wrapper .promo-container .promo-code .promo-remove {
        color: red;
        font-size: 14px;
        font-weight: 600;
        align-self: center;
        cursor: pointer;
    }