.slider {
}

.slider__flex {
    /* width: 1120px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slider__images {
    width: 100%;
    /* height: 100%; */
}

.swiper-container2 {
    /* height: 100%; */
    width: 100%;
    /* max-height: 450px; */
}

.swiper-container2 *.swiper-slide  {
    width: 100%;
    max-height: 620px;
}



.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal.swiper-pagination-bullets-dynamic {
    padding: 8px;
    background-color: black;
    opacity: 0.6;
    border-radius: 50px;
    margin-bottom: 5px !important;
    max-height: 450px;
}
.home-container .slider .swiper-pagination .swiper-pagination-bullet-active-main {

    background-color: var(--secondary-color);
}

.home-container .slider .swiper-pagination .swiper-pagination-bullet-active-prev,
.home-container .slider .swiper-pagination .swiper-pagination-bullet-active-next {
    background-color: #ffffff ;
    opacity: 0.4 !important;

}

.swiper-button-prev {
    color: #000 !important;
}
.swiper-button-next {
    color: #000 !important;
}
 
.swiper-container2 .slider__image {
    width: 100%;
  }
  
  .swiper-container2 .slider__image img {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    /* max-height: 1080px; */
    border-radius: 30px;
  }

/* container of indexer of sliders */
.slider .swiper-container1 > div{
    display: flex;
    gap: 2rem;
    padding: 10px 5px;
}

.slider__col {
    display: flex;
    align-items: center;
    margin-left: 62px;
}

.swiper-container1 *.swiper-slide{
    width: fit-content;
}


.swiper-container1 *.slider__image img {
    width: 100%;
    height: 100%;
    border-radius: 30px !;
}
.all_categorys{
    background-color: #066232;
    padding: 8px 40px;
    border-radius: 16px;
    cursor: pointer;
}
.category-list{
    padding: 0;
    list-style-type: none;
}
.category-list img{
    width: 61px;
    padding: 10px;
    border-radius: 50%;
    height: 61px;
}
.ctg_name{
    text-align: center;
    align-content: center;
    width: 100%;
    margin: 0;
    padding: 0;
}
.category-list .link{
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    
}
.category-list li{
    border-top: solid 1px #0092452b;
}
.category-list{
    margin: 0 !important;
}
.list_categorys{
    position: absolute;
    background-color: white;
    width: 219px;

    border-radius: 9px;
    display: none;
    bottom: 0;
    transform: translateY(calc(100% + 9px));
}
.show_category{
    display: block;
}
.hide_category{
    display: none;
}
.list_categorys_overflow{
    max-height: 370px;
    overflow: auto;
}
.list_categorys>svg{
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
}
.list_categorys>svg>path{
    color: white;
}
.category-list li:hover{
    background-color: rgb(0 146 69 / 20%);
}
.menu_icon>svg{
    color: white;
}
.icons_media_icon>a{
    padding: 0 16px !important
}
.btn-menu {
    padding: 10px;
}
.all_categorys:hover{
    background-color: #ffffff;
}
.all_categorys:hover .menu_icon path{
    color: #009245ba;
}
.all_categorys:hover>.all_categorys_text{
    color: #009245ba;
}

.menu_icon path{
    color: white;
}
.all_categorys>.all_categorys_text{
    color: white;
    font-weight: bold;
}
.slider>.nav_slide{
    position: absolute;
    top: 0;
    width: 100%;
    background-color: #009245ba;
    z-index: 100;
    border-radius: 0 0 10px 10px;
    padding: 18px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
}


/* shimmer */
.slider-shimmer {
    width: 100%;
    /* height: 400px; */
}


@media (min-width:768px){
    .swiper-container2 .slider__image img{
        /* height: 350px ; */
    }
}
@media (min-width:992px){
    .swiper-container2 .slider__image img{
        /* height: 500px ; */
    }
}
@media (min-width:1200px){
    .swiper-container2 .slider__image img{
        /* height: 800px ; */
    }
}